import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import gaussian from 'gaussian';
import classnames from 'classnames';

import Login from '../components/dashboard/login';
import Map from '../components/dashboard/map';
import Api from '../lib/Api';
import MinimalLayout from "../components/layout/minimal";
import SEO from "../components/seo";
import styles from '../components/styles/dashboard.module.scss';
import Logo from '../images/logo.png';

const covidStatuses = {
    'positive': 'Positive',
    'negative': 'Negative',
    'not-tested': 'Not Tested',
};

const selectDot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',
    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
        flexShrink: 0,
    },
});

const getDataColor = (status) => {
    switch (status) {
        case 'positive':
            return '#b10f0f';
        case 'negative':
            return '#1255cc';
        default:
            return '#b0b0b0';
    }
};
const getDataBackgroundColor = (status) => {
    switch (status) {
        case 'positive':
            return '#f5c6cb';
        case 'negative':
            return '#c6c8f5';
        default:
            return '#d6d8db';
    }
};

const FeatureListItem = ({ feature, onZoomTo }) => {
    return (
        <li 
            className={classnames(styles.sidebarListItem, { 
                [styles.sidebarListItemInfectious]: feature.properties.type === 'infectious',
                [styles.sidebarListItemPossibleExposure]: feature.properties.type === 'possible-exposure',
            })} 
            onClick={() => onZoomTo(...feature.geometry.coordinates)}
        >
            <span>{feature.properties.name}</span><br />
            <span className="text-muted">{feature.properties.date}</span><br />
            <div>
                <span className="small text-muted">{feature.properties.duration}</span>
                <span 
                    className={classnames('badge', 'ml-1', { 
                        [styles.badgeInfectious]: feature.properties.type === 'infectious',
                        [styles.badgePossibleExposure]: feature.properties.type === 'possible-exposure',
                        [styles.badgeUnknown]: feature.properties.type === 'unknown',
                    })}
                >{feature.properties.status}</span>
            </div>
        </li>
    );
};

const statusOptions = [
    { label: 'Any', value: 'any' },
    { label: 'Positive', value: 'positive' },
    { label: 'Negative', value: 'negative' },
    { label: 'Not Tested', value: 'not-tested' },
];

const selectColorStyles = {
    control: styles => ({ ...styles, backgroundColor: '#fff' }),
    option: (styles, { data }) => ({ ...styles, ...selectDot(data.meta.color) }),
    singleValue: (styles, { data }) => ({ ...styles, ...selectDot(data.meta.color) }),
};

const DashboardPage = () => {
    const [token, setToken] = useState(null);

    const [sidebarList] = useRef(null);
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState(null);
    const [statusFilter, setStatusFilter] = useState('any');
    const [geoData, setGeoData] = useState(null);
    const [mapViewport, setMapViewport] = useState({
        latitude: 39.82,
        longitude: -98.52,
        zoom: 4,
    });

    const getUsers = async () => {
        try {
            const users = await Api.getDashboardUsers(token);
            const usersOptions = users.map(user => ({
                value: user.user_id,
                label: user.user_id,
                meta: {
                    color: getDataColor(user.covid_status),
                    backgroundColor: getDataBackgroundColor(user.covid_status),
                    covidStatusLabel: user.covid_status ? covidStatuses[user.covid_status] : 'Unknown',
                    ..._.omit(user, ['user_id']),
                },
            }));

            setUsers(usersOptions);
            if (usersOptions.length > 0) {
                setUserId(usersOptions[0].value);
            }
        } catch (err) {
            if (err.statusCode === 403) {
                setToken(null);
            }

            window.alert(err.message);
        }
    };

    const getDataForUser = async () => {
        const { collection } = await Api.getDashboardData(userId, token);
        setGeoData(collection);

        // Initialize map zoom for user
        if (collection.features.length > 0) {
            const firstFeature = collection.features[0];
            setMapViewport({
                ...mapViewport,
                longitude: firstFeature.geometry.coordinates[0],
                latitude: firstFeature.geometry.coordinates[1],
                zoom: 9,
            });
        }

        if (sidebarList?.current) {
            sidebarList.current.scrollTop = 0;
        }
    };

    useEffect(() => {
        if (token) {
            getUsers();
        }
    }, [token]);

    useEffect(() => {
        if (userId) {
            getDataForUser();
        } else {
            setGeoData(null);
        }
    }, [userId]);

    const handleFeatureZoom = (longitude, latitude) => {
        setMapViewport({ 
            ...mapViewport,
            longitude,
            latitude,
            zoom: 14,
        });
    };

    const filteredUsers = users.filter(user => statusFilter === 'any' || user.meta.covid_status === statusFilter);
    const currentUser = users.find(user => user.value === userId);
    const normalDist = currentUser && currentUser.meta.cmi && gaussian(currentUser.meta.cmi_avg, Math.pow(currentUser.meta.cmi_sd, 2));
    const normalPdf = normalDist && normalDist.pdf(currentUser.meta.dm);

    return (
        <MinimalLayout>
            <SEO title="Dashboard" />
            <main className={styles.main}>
                {token ? (
                    <div className={styles.pageWrapper}>
                        <div className={styles.sidebar}>
                            <img src={Logo} alt="Covidseeker" />

                            <div className={styles.sidebarFilter}>
                                <span>Filter by test status:</span>
                                <Select 
                                    options={statusOptions}
                                    value={statusOptions.find(option => statusFilter === option.value)}
                                    onChange={option => {
                                        setStatusFilter(option.value);
                                        if (currentUser?.meta.covid_status !== option.value) {
                                            setUserId(null);
                                        }
                                    }}
                                    className="react-select"
                                />
                            </div>
                            
                            <div className={styles.sidebarFilter}>
                                <span>User ID:</span>
                                <Select 
                                    options={filteredUsers}
                                    value={currentUser || null}
                                    onChange={option => setUserId(option.value)}
                                    className="react-select"
                                    styles={selectColorStyles}
                                />
                            </div>

                            <ul className={styles.sidebarList} ref={sidebarList}>
                                {currentUser &&
                                    <div className="border-bottom p-2">
                                        <table className="table-sm w-100">
                                            <tbody>
                                                <tr>
                                                    <th scope="col">COVID Status</th>
                                                    <td style={{ backgroundColor: currentUser.meta.backgroundColor }}>
                                                        {currentUser.meta.covidStatusLabel}
                                                    </td>
                                                </tr>
                                                {currentUser.meta.date_tested &&
                                                    <tr>
                                                        <th scope="col">Date Tested</th>
                                                        <td>{currentUser.meta.date_tested}</td>
                                                    </tr>
                                                }
                                                {currentUser.meta.covid_like_status &&
                                                    <tr>
                                                        <th scope="col">Had Symptoms?</th>
                                                        <td>{currentUser.meta.covid_like_status}</td>
                                                    </tr>
                                                }
                                                {currentUser.meta.date_covid_like &&
                                                    <tr>
                                                        <th scope="col">Date Symptomatic</th>
                                                        <td>{currentUser.meta.date_covid_like}</td>
                                                    </tr>
                                                }
                                                {currentUser.meta.dm &&
                                                    <tr>
                                                        <th scope="col">
                                                            <abbr title="Device Mobility, this user's personal mobility index">DM</abbr>
                                                        </th>
                                                        <td className={classnames({
                                                            'table-danger': normalPdf && normalPdf < .25,
                                                            'table-warning': normalPdf && normalPdf >= .25 && normalPdf < .66,
                                                            'table-success': normalPdf && normalPdf >= .66,
                                                        })}>
                                                            {currentUser.meta.dm.toFixed(2)}<br />
                                                            {normalDist && <>[<strong>percentile:</strong> {(normalPdf * 100).toFixed(1)}]</>}
                                                        </td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th scope="col">Date Uploaded</th>
                                                    <td>{currentUser.meta.created_at}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                }

                                {geoData && (
                                    geoData.features.length > 0 ? (
                                        geoData.features.map((feature, i) => 
                                            <FeatureListItem key={i} feature={feature} onZoomTo={handleFeatureZoom} />
                                        )
                                    ) : (
                                        <p className="p-3">No data to display.</p>
                                    )
                                )}
                            </ul>
                        </div>
                        <div className={styles.mapWrapper}>
                            <Map 
                                geoData={geoData} 
                                viewport={mapViewport} 
                                onViewportChange={setMapViewport} 
                            />
                        </div>
                    </div>
                ) : (
                    <Login setToken={token => setToken(token)} />
                )}
            </main>
        </MinimalLayout>
    );
};

export default DashboardPage;
